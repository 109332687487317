._1I1XanaMP_ {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  min-width: 60px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 0.5rem;
}
._1I1XanaMP_.wFyyIZguZF ._1IWMoZPmtM {
  color: #3d73ff;
}
._1I1XanaMP_:not(.wFyyIZguZF) ._1IWMoZPmtM {
  color: #ff5c5c;
}
._1IWMoZPmtM {
  margin-right: 0.25rem;
}
