._2QJrFs0T_W {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  min-width: 50rem;
}
@media screen and (min-width: 769px) {
  ._2QJrFs0T_W {
    min-height: 720px;
  }
}
@media screen and (max-width: 768px) {
  ._2QJrFs0T_W {
    min-height: 480px;
  }
}
.iP-YWxQQFT {
  border-bottom: 1px solid #efefef;
  background-color: #fff;
  height: 3rem;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._2PKqh9CFtm ._17DYoNhpQO {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
._2PKqh9CFtm > div:not(._3U3yDur5Y6):not(:last-child) {
  font-weight: 600;
}
._2PKqh9CFtm,
._2hO606sZf4 {
  padding: 0.25rem 0 0.25rem 0.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
._2PKqh9CFtm > div:not(._3U3yDur5Y6):not(._11hcAnrO4s):not(:last-child),
._2hO606sZf4 > div:not(._3U3yDur5Y6):not(._11hcAnrO4s):not(:last-child) {
  padding-right: 0.75rem;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  white-space: nowrap;
}
._2lnYyux14- {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  right: 0.75rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
@media screen and (min-width: 621px) {
  ._2lnYyux14- {
    position: sticky;
  }
}
._2lnYyux14- ._3b5gebzthc {
  background-color: #fff;
}
.LssGS1JbDr {
  border: 1px solid #efefef;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 15rem;
  height: 13rem;
  overflow: hidden;
}
.LssGS1JbDr ._2hO606sZf4 {
  height: 30px;
}
._1Zo8yWFX16 {
  font-weight: 600;
}
._3-TaB5tdQD {
  overflow: hidden;
  background-color: #fafafa;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
}
._3-TaB5tdQD ol {
  padding-left: 0;
  margin: 0;
  list-style: none;
}
._3Sh35nV1mn {
  border-top: 1px solid #efefef;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 0.857rem;
  color: #d18100;
  padding: 0.25rem 0;
}
._3Sh35nV1mn span {
  margin-right: 0.25rem;
}
._2hO606sZf4 {
  height: 40px;
  background-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._2hO606sZf4._3QRDaSOG5U {
  background-color: #fff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 2rem 0 rgba(0,0,0,0.1);
}
._2hO606sZf4.eqD3Xc28Wl {
  padding: 0 0 0 0.5rem;
}
._2hO606sZf4.eqD3Xc28Wl ._28aST1BlrB {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._2hO606sZf4:not(.eqD3Xc28Wl) ._3U3yDur5Y6 {
  overflow-y: auto;
}
._2hO606sZf4._1ll9tkZNXt {
  background-color: #fff7ea;
}
._2hO606sZf4._1ll9tkZNXt ._17DYoNhpQO {
  color: #d18100;
}
._2hO606sZf4:not(.eqD3Xc28Wl) {
  cursor: pointer;
  -webkit-transition: background-color ease-in-out 0.12s;
  -moz-transition: background-color ease-in-out 0.12s;
  -o-transition: background-color ease-in-out 0.12s;
  -ms-transition: background-color ease-in-out 0.12s;
  transition: background-color ease-in-out 0.12s;
}
._2hO606sZf4:not(.eqD3Xc28Wl):hover:not(._3QRDaSOG5U) {
  background-color: #f3f3f3;
}
._2hO606sZf4 ._17DYoNhpQO,
._2hO606sZf4 ._28aST1BlrB,
._2hO606sZf4 .jZJ_cpQxBu,
._2hO606sZf4 ._11hcAnrO4s {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.857rem;
}
._2hO606sZf4 ._17DYoNhpQO {
  color: #868686;
}
._2hO606sZf4 ._28aST1BlrB span,
._2hO606sZf4 .jZJ_cpQxBu span {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
._17DYoNhpQO {
  -webkit-flex-basis: 6rem;
  flex-basis: 6rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.jZJ_cpQxBu {
  -webkit-flex-basis: 12%;
  flex-basis: 12%;
  min-width: 6rem;
}
._2hO606sZf4:not(.eqD3Xc28Wl) ._28aST1BlrB,
._2PKqh9CFtm ._28aST1BlrB {
  -webkit-flex-basis: 17%;
  flex-basis: 17%;
  min-width: 14rem;
  max-width: 20rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._3U3yDur5Y6 {
  -webkit-flex-basis: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding-right: 0;
  white-space: nowrap;
}
._3U3yDur5Y6::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}
._26pFdeH6cq,
._11hcAnrO4s {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
._11hcAnrO4s {
  color: #ea0000;
}
._1fc6H4VIk0 {
  margin-right: 0.25rem;
}
._1jinPd2dot {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
._2ZSmN5zglF {
  color: #868686;
}
._2ZSmN5zglF pre {
  display: inline;
  font-size: 0.857rem;
}
._1SWhNlg35v {
  border-left: 1px solid #efefef;
  background-color: #fff;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media screen and (min-width: 769px) {
  ._1SWhNlg35v {
    -webkit-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -moz-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -o-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -ms-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -webkit-transition-duration: 330ms;
    -moz-transition-duration: 330ms;
    -o-transition-duration: 330ms;
    -ms-transition-duration: 330ms;
    transition-duration: 330ms;
    -webkit-transition-property: right;
    -moz-transition-property: right;
    -o-transition-property: right;
    -ms-transition-property: right;
    transition-property: right;
    z-index: 1;
    height: 100%;
    position: absolute;
    width: 43rem;
    right: -43rem;
  }
  ._1SWhNlg35v._3yWDwFOiMD {
    right: 0;
    -webkit-box-shadow: 0 0 8rem 2rem rgba(0,0,0,0.2);
    box-shadow: 0 0 8rem 2rem rgba(0,0,0,0.2);
  }
}
@media screen and (max-width: 768px) {
  ._1SWhNlg35v {
    -webkit-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -moz-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -o-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -ms-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -webkit-transition-duration: 330ms;
    -moz-transition-duration: 330ms;
    -o-transition-duration: 330ms;
    -ms-transition-duration: 330ms;
    transition-duration: 330ms;
    -webkit-transition-property: left;
    -moz-transition-property: left;
    -o-transition-property: left;
    -ms-transition-property: left;
    transition-property: left;
    z-index: 2000;
    position: fixed;
    width: 100vw;
    left: 100vw;
    top: 4rem;
    height: calc(100vh - 4rem);
  }
  ._1SWhNlg35v._3yWDwFOiMD {
    left: 0vw;
  }
}
._1ynsp3Rzqz {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 0.5rem;
  height: 3rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
}
._29YVp5HF7c {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  overflow: auto;
}
._1J-6JQxg_D {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-align: inherit;
  width: 2rem;
  height: 100%;
  padding: 0.25rem;
}
._1TJJ_9S26D {
  font-weight: 600;
}
