@media screen and (min-width: 769px) {
  .ktGB9Alvjx {
    position: relative;
  }
  .ktGB9Alvjx ._1fefTMYFet,
  .ktGB9Alvjx ._3MAVq6kKIZ,
  .ktGB9Alvjx ._1q6IT9gZ3x {
    display: none;
  }
  .ktGB9Alvjx .nGq7KIyCky {
    margin-right: 0;
  }
  .ktGB9Alvjx ._2BCSMR-DJ3,
  .ktGB9Alvjx ._19IUF1zdAw {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 1rem 0;
    height: auto;
    -webkit-box-pack: space-evenly;
    -moz-box-pack: space-evenly;
    -o-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
  }
  .ktGB9Alvjx:focus-within ._8V9a2BtHU3,
  .ktGB9Alvjx:hover ._8V9a2BtHU3 {
    display: block;
  }
}
._19IUF1zdAw {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-align: inherit;
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  display: block;
  text-align: left;
  position: relative;
  padding: 0.75rem 4rem 0.75rem 1.5rem;
  height: 3.1rem;
  -webkit-transition: background-color 0.25s, color 0.12s ease-in;
  -moz-transition: background-color 0.25s, color 0.12s ease-in;
  -o-transition: background-color 0.25s, color 0.12s ease-in;
  -ms-transition: background-color 0.25s, color 0.12s ease-in;
  transition: background-color 0.25s, color 0.12s ease-in;
}
._19IUF1zdAw:active {
  background-color: #fff;
}
._1-QlUk0gno,
._19IUF1zdAw:hover {
  color: #3d73ff;
}
._2BCSMR-DJ3 {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  color: #363636;
  padding: 1rem 4rem 1rem 1.5rem;
  display: block;
  position: relative;
}
._2BCSMR-DJ3:not(._3rrw9tQbgM)::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #fafafa;
  -webkit-transition: width 0.12s ease-out 0s, background-color 0.12s linear;
  -moz-transition: width 0.12s ease-out 0s, background-color 0.12s linear;
  -o-transition: width 0.12s ease-out 0s, background-color 0.12s linear;
  -ms-transition: width 0.12s ease-out 0s, background-color 0.12s linear;
  transition: width 0.12s ease-out 0s, background-color 0.12s linear;
}
._2BCSMR-DJ3:hover:not(._3rrw9tQbgM)::before {
  width: 100%;
  -webkit-transition: width 0.12s ease-out 0.25s, background-color 0.12s linear;
  -moz-transition: width 0.12s ease-out 0.25s, background-color 0.12s linear;
  -o-transition: width 0.12s ease-out 0.25s, background-color 0.12s linear;
  -ms-transition: width 0.12s ease-out 0.25s, background-color 0.12s linear;
  transition: width 0.12s ease-out 0.25s, background-color 0.12s linear;
}
._2BCSMR-DJ3::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #aaa;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.25s ease-in-out, background-color 0.12s linear;
  -moz-transition: -moz-transform 0.25s ease-in-out, background-color 0.12s linear;
  -o-transition: -o-transform 0.25s ease-in-out, background-color 0.12s linear;
  -ms-transition: -ms-transform 0.25s ease-in-out, background-color 0.12s linear;
  transition: transform 0.25s ease-in-out, background-color 0.12s linear;
}
._2BCSMR-DJ3:hover:not(._3rrw9tQbgM)::after {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
._3rrw9tQbgM {
  background-color: #fafafa;
  color: #3d73ff;
}
._3rrw9tQbgM::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  display: block;
  width: 3px;
  background: #3d73ff;
}
._3rrw9tQbgM .nGq7KIyCky {
  color: #3d73ff;
}
.nGq7KIyCky {
  margin-right: 0.5rem;
  vertical-align: middle;
  color: #686868;
}
._1fefTMYFet {
  vertical-align: middle;
}
._3MAVq6kKIZ {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.25s ease-in;
  -moz-transition: -moz-transform 0.25s ease-in;
  -o-transition: -o-transform 0.25s ease-in;
  -ms-transition: -ms-transform 0.25s ease-in;
  transition: transform 0.25s ease-in;
}
._2FaLZLrSxs {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
._8V9a2BtHU3 {
  display: none;
  left: 3rem;
  top: 0;
}
._8V9a2BtHU3::before {
  content: '';
  position: absolute;
  top: -30px;
  bottom: -30px;
  left: -10px;
  right: -30px;
  z-index: -1;
}
